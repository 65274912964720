// Here you can add other styles

.category-options {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.8s;
  &:hover {
    opacity: 1;
  }
}
.slide-title {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.8s;
  &:hover {
    opacity: 1;
  }
}

.category-loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.category-icon {
  width: 2rem !important;
  height: 2rem !important;
  cursor: pointer;
}

.product-item {
  border-radius: 0.75rem !important;
}

.text-error {
  color: #e55353 !important;
}

.card-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.card-shadow:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.custom-dropdowm-item {
  cursor: pointer;
}

.custom-dropdowm-item:hover {
  background-color: $light;
}

.custom-dropdowm-item:not(:last-child) {
  border-bottom: 1px solid $light;
}
.rounded-image {
  border-radius: 50%;
}
